import { addPropertyControls, ControlType } from "framer"
import {
    propertyControls,
    Countdown as Component,
} from "https://boosters.flowbase.co/countdown-framer.js#Pi7EuYI4gQpP"

addPropertyControls(Countdown, propertyControls)

export default function Countdown(props) {
    return <Component {...props} />
}
